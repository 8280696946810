import React from 'react'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
        <div style={{ height : "100vh", border: "0px solid" }}>
          <SEO title="404: Not Found" />

          <body style={{ background: '#003366', width: "100%", height : "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "3em", border: "0px solid" }}>
            <img
              src="http://i.giphy.com/VGuAZNdkPUpEY.gif"
              style={{ width: "500px", height: "261px" }}
            />

            <div style={{ color: "#ECF0F1", fontSize: "36px", textAlign: "center", margin: "0 16px" }}>
              Uh oh, something's not right…
            </div>

            <a href="https://build.ms">
              <div className="box-button primary-shadow" style={{}}>
                Lets Go Home
              </div>
            </a>
          </body>
      </div>
    )
  }
}

export default NotFoundPage
